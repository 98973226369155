<template>
  <div class="pa-2">
    <v-card>
      <v-card-title>
        <div></div>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            @click="viewDialogImportAV100 = true"
            color="primary"
            class="ma-1"
          >
            Импорт AV100
          </v-btn>
          <v-btn
            @click="viewDialogImportPromo = true"
            color="primary"
            class="ma-1"
          >
            Импорт промокодов
          </v-btn>
          <v-btn @click="viewDialogImport = true" color="primary" class="ma-1">
            Импорт новых заявок
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text :style="{ height: contentHeight + 'px' }">
        <a-table-f-api
          ref="table"
          :api="getUrl"
          :model="model"
          :useQuery="true"
          :defaults="getConfigView(viewName).index.defaults"
          @click="onClickRow($event)"
        />
      </v-card-text>
    </v-card>
    <dialogImport v-model="viewDialogImport" />
    <dialogImportPromo v-model="viewDialogImportPromo" />
    <dialogImportAv100 v-model="viewDialogImportAV100" />
  </div>
</template>

<script>
import genModel from "@/share/components/libs/genModel";
import table from "./table";

export default {
  mixins: [table, genModel],
  components: {
    dialogImport: () => import("./dialogImport"),
    dialogImportPromo: () => import("./dialogImportPromo"),
    dialogImportAv100: () => import("./dialogImportAv100.vue"),
  },
  data() {
    return {
      viewDialogImportAV100: false,
      viewDialogImport: false,
      viewDialogImportPromo: false,
    };
  },
  computed: {
    getUrl() {
      return (
        this.$root.api[this.$route.params.api] + "/" + this.$route.params.view
      );
    },
    model() {
      this.$root.title = this.getConfigView(this.viewName).name;
      return this.modelIndex;
    },
  },
  methods: {
    onResize() {
      let height = window.innerHeight;
      this.contentHeight = height - 146;
    },
    async onClickRow(e) {
      this.$router.push({
        name: "get-card-id",
        params: { id: e.row.id },
      });
    },
  },
};
</script>